import './util/polyfill'
import 'react-app-polyfill/ie9' // For IE 9-11 support
import './components/reset.css'

import {assert, defer} from './util/common'
import {getAnalyticsSubscriber, getStorageSubscriber} from './redux/subscribers'

import App from './app/App'
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom'
import {StrictMode} from 'react'
import {configureStore} from '@reduxjs/toolkit'
import {getPersistedState} from './redux/ReduxState'
import {getTrackingParams} from './util/url'
import {maybeToggleFaviconForDarkMode} from './util/image'
import reducer from './redux/reducers'
import reportWebVitals from './app/reportWebVitals'

const store = configureStore({
  reducer,
  preloadedState: getPersistedState(),
})
store.subscribe(getStorageSubscriber(store))
store.subscribe(getAnalyticsSubscriber(store))

unregisterServiceWorker()

const props = {
  trackingParams: getTrackingParams(window.location.search),
  isCustomDomain: !!window.__MLZ_IS_CUSTOM_DOMAIN__,
}

// TODO(mime/jared): prbly should move these providers to the Providers
// component in App.js
async function renderAppTree(app) {
  return (
    <StrictMode>
      <Provider store={store}>{app}</Provider>
    </StrictMode>
  )
}

// This enables hot module reloading for JS (HMR).
// $FlowFixMe - Flow doesn't know the type of module.hot so ignore
if (module?.hot?.accept) {
  // NB: in development, we support HMR (hot module reloading).
  // Problem is that when the App reloads, especially on organizer side,
  // these values have been cleared away and the app crashes.
  // This breaks away slightly from how prod works but the trade-off here
  // is that development cycles are faster.
  window.__MLZ_CACHED_LOCALDEV_EMBEDDED_DATA__ = window.__MLZ_EMBEDDED_DATA__

  module.hot.accept('./app/App', async () => {
    const NextApp = require('./app/App').default
    const appTree = await renderAppTree(<NextApp {...props} />)
    window.__MLZ_EMBEDDED_DATA__ = window.__MLZ_CACHED_LOCALDEV_EMBEDDED_DATA__
    ReactDOM.render(appTree, assert(document.getElementById('root')))
  })
}

async function render() {
  ReactDOM.render(
    await renderAppTree(<App {...props} />),
    assert(document.getElementById('root'))
  )
}
render()

function unregisterServiceWorker() {
  // In a try-catch because we get a lot of errors of the nature
  // "Object.<anonymous>(index) -> undefined is not a function"
  // and we just ignore these anyway.
  // At the same time, this is called *before* we render the app
  // so I want to make sure it doesn't cause the page to not render :-/
  try {
    if ('serviceWorker' in navigator && navigator.serviceWorker) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister()
        })
        .catch((error) => {
          console.error(error.message)
        })
    }
  } catch (e) {
    // Do nothing.
  }
}

// Defer favicon toggling so as to not initially block rendering.
defer(maybeToggleFaviconForDarkMode)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
