import {
  filterParamsToQueryString,
  maybeGetSignupIdentityFieldsFromCurrentVolunteer,
  queryStringToFilterParams,
} from 'util/feed'
import {formatQueryString, getQueryParams} from 'util/url'
import {getIdentityFields, isEveryIdentityFieldFilledOut} from 'util/user'

import {ChatSnippetInitializationMode} from 'vendor/intercom/chatSnippetManager'
import {Component} from 'react'
import {EventChainingAction} from 'app/enums'
import EventFeed from './Feed'
import EventSignupChainingModal from 'events/modals/EventSignupChainingModal'
import MembershipConfirmationModal from 'events/modals/MembershipConfirmationModal'
import api from 'data/api'
import {connect} from 'react-redux'
import {getOrganizationFeedUrl} from 'util/routing'
import {isValidLatLon} from 'util/geo'
import {pick} from 'util/common'
import withOrgAnalytics from 'analytics/withOrgAnalytics'
import withScreenData from 'app/withScreenData'

function getFilterParams(props) {
  if (
    (props.data.landing_page?.allow_partial_query_string_match ?? false) &&
    props.location.search
  ) {
    const default_filter_params = getQueryParams(
      props.data.landing_page.filter_param_query_string
    )
    let merged_params = getQueryParams(props.location.search)
    for (const key in default_filter_params) {
      if (!(key in merged_params)) {
        merged_params[key] = default_filter_params[key]
      }
    }

    return queryStringToFilterParams(formatQueryString(merged_params))
  }

  return queryStringToFilterParams(
    props.data.landing_page
      ? props.data.landing_page.filter_param_query_string
      : props.location.search
  )
}

class EventFeedScreen extends Component {
  state = {
    shownOrgs: null,
    timeslotRecommendations: [],
  }

  componentDidMount() {
    const {
      location,
      onFeedOpenedWithReshiftMethod,
      onFeedOpenedWithFlakeReshiftMethod,
    } = this.props

    const queryParams = getQueryParams(location.search)
    const maybeReshiftMethod = queryParams.reshift_method || null
    if (maybeReshiftMethod) {
      onFeedOpenedWithReshiftMethod(maybeReshiftMethod)
    }
    const maybeFlakeReshiftMethod = queryParams.reshift2_method || null
    if (maybeFlakeReshiftMethod) {
      onFeedOpenedWithFlakeReshiftMethod(maybeFlakeReshiftMethod)
    }

    this.maybeLoadOrgsForFiltering()
    this.maybeOpenChainingModal()
  }

  maybeLoadOrgsForFiltering = async () => {
    const {current_organization: org} = this.props.data

    try {
      const shownOrgs = await api.getOrgsForFiltering(org.slug)
      this.setState({shownOrgs})
    } catch (e) {
      // No-op; exception is sent to Sentry in api.js
    }
  }

  maybeOpenChainingModal = async () => {
    const {
      currentVolunteer,
      location,
      data: {current_organization},
    } = this.props
    const {followup_modal_context, participation_id} = getQueryParams(
      location.search
    )
    // Fetch timeslot recommendations (and then show the dialog if there are any) if:
    if (
      // 1) The user is coming from a place instructing us to open the dialog
      EventChainingAction[followup_modal_context] && // `EventChainingAction[undefined]` is falsy
      parseInt(participation_id) && // `parseInt(undefined)` is NaN, which is falsy
      // 2) The user is prefilled on this browser
      maybeGetSignupIdentityFieldsFromCurrentVolunteer(currentVolunteer) &&
      // 3) The org does not have the membership gate modal
      !current_organization.require_membership_for_feed
    ) {
      let timeslots = []
      try {
        const response = await api.getTimeslotRecommendationsForParticipation(
          parseInt(participation_id)
        )
        timeslots = response.timeslots
      } catch (e) {}
      this.setState({timeslotRecommendations: timeslots})
    }
  }

  onCloseChainingModal = () => this.setState({timeslotRecommendations: []})

  onFilterChange = (filterParams) => {
    // update the query string and then let the resultant prop change (in
    // props.location.search, from the router) do the actual updating (via
    // withScreenData api fetch)
    const parsedSearch = pick(
      // preserve per_page
      getQueryParams(this.props.location.search),
      'per_page'
    )

    const search = filterParamsToQueryString(filterParams, parsedSearch)
    // Push the pathname too so that filtering from a landing page takes you back to the feed
    const {pathname} = getOrganizationFeedUrl(
      this.props.data.current_organization
    )
    this.props.history.push({pathname, search})
  }

  render() {
    const {
      data,
      trackingParams,
      currentVolunteer,
      feedUser,
      location,
      isLoading,
    } = this.props
    const {
      events,
      geolocated_city: geolocatedCity,
      is_geolocated: isGeolocated,
      paging_info,
      current_organization: organization,
      filter_tags: filterTags,
      org_tag_ids: orgTagIds,
      landing_page: landingPage,
      event_campaign: eventCampaign,
      group,
      user,
    } = data
    const filterParams = getFilterParams(this.props)
    const {shownOrgs, timeslotRecommendations} = this.state
    const signupIdentityFields = getIdentityFields({
      user,
      location,
      currentVolunteer,
      feedUser,
    })
    const {followup_modal_context, from_map} = getQueryParams(location.search)
    const eventChainingAction = EventChainingAction[followup_modal_context]
    const isFilteredToMapPosition =
      from_map && isValidLatLon({lat: filterParams.lat, lon: filterParams.lon})

    return (
      <>
        {timeslotRecommendations.length > 0 &&
          isEveryIdentityFieldFilledOut(signupIdentityFields) &&
          eventChainingAction && (
            <EventSignupChainingModal
              isOpen
              timeslots={timeslotRecommendations}
              onRequestClose={this.onCloseChainingModal}
              organization={organization}
              action={eventChainingAction}
              trackingParams={trackingParams}
              signupIdentityFields={signupIdentityFields}
            />
          )}
        <MembershipConfirmationModal
          organization={organization}
          trackingParams={trackingParams}
        />
        <EventFeed
          organization={organization}
          landingPage={landingPage}
          eventCampaign={eventCampaign}
          events={events}
          onFilterChange={this.onFilterChange}
          isLoading={isLoading}
          filterParams={filterParams}
          pagingInfo={paging_info}
          shownOrgs={shownOrgs}
          filterTags={filterTags}
          orgTagIds={orgTagIds}
          isGeolocated={isGeolocated}
          geolocationName={
            isFilteredToMapPosition && !geolocatedCity
              ? 'map position'
              : geolocatedCity
          }
          currentVolunteer={currentVolunteer}
          group={group}
        />
      </>
    )
  }
}

const additionalTrackPageLoadedProps = (props) => {
  const {
    data: {current_organization: organization},
  } = props
  const {location} = props

  const filterParams = getFilterParams(props)
  const queryParams = getQueryParams(location.search)
  const maybeReshiftMethod = queryParams.reshift_method || null
  const maybeFlakeReshiftMethod = queryParams.reshift2_method || null

  return {
    feed_filter_zip:
      filterParams.zip && filterParams.zip !== '' ? filterParams.zip : null,
    feed_filter_date: filterParams.date,
    feed_filter_eventType: filterParams.eventTypes?.[0],
    feed_filter_eventTypes: [...(filterParams.eventTypes || [])].sort(),
    feed_filter_maxDist: filterParams.maxDist,
    reshift_method: maybeReshiftMethod,
    flake_reshift_method: maybeFlakeReshiftMethod,
    feed_lead_capture: organization.lead_capture_in_feed,
    num_events_on_feed: props.data.events.length,
  }
}

const mapStateToProps = (state) => ({
  currentVolunteer: state.currentVolunteer,
  feedUser: state.feedUser,
})

export default withScreenData(
  withOrgAnalytics(connect(mapStateToProps)(EventFeedScreen)),
  {
    cacheResponse: true,
    queryParamsToRemove: ['error', 'reason'],
    additionalTrackPageLoadedProps,
    analyticsPageNameOverride: (props) =>
      props.data.landing_page ? 'landing_page' : 'feed',
    chatSnippetInitializationMode:
      ChatSnippetInitializationMode.USE_CLIENT_CHAT_SNIPPET_ONLY,
  }
)
