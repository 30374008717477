import {formatQueryString, getQueryParams} from 'util/url'
import {getMobilizeFeedUrl, getOrganizationFeedUrl} from 'util/routing'
import {logError, mapObject, omit} from 'util/common'

import {DJANGO_CSRF_COOKIE_NAME} from 'app/constants'
import cookies from 'browser-cookies'
import {filterParamsPartialToQueryParams} from 'util/feed'

const SCREEN_RESPONSE_CACHE = new Map()

export class ApiError extends Error {
  response
  json
  status
  code
  extraData
  fieldErrors

  constructor(
    msg,
    res,
    json = null,
    code,
    extraData = null,
    fieldErrors = null
  ) {
    // A bit hacky but we can't update `settings.CSRF_FAILURE_VIEW` on the backend
    // to customize this there :-/
    // 'CSRF Failed' comes from https://github.com/encode/django-rest-framework/blob/master/rest_framework/authentication.py
    const csrfMsg = `Looks like we can't save your changes. Refreshing your browser will probably fix this error. (${
      msg || ''
    })`
    super(msg?.includes('CSRF Failed') ? csrfMsg : msg)

    this.response = res
    this.json = json
    this.status = res.status
    this.code = code
    this.extraData = extraData
    this.fieldErrors = fieldErrors || []

    // See above.
    if (this.json && this.json?.error?.message?.includes('CSRF Failed')) {
      this.json.error.message = csrfMsg
      this.json.error.detail = csrfMsg
    }
  }
}

export class ApiRedirectError extends Error {
  url
  response
  isRedirect

  constructor(url, response) {
    super(url)
    this.url = url
    this.response = response
    this.isRedirect = true
  }
}

/**
 * Fetch a JSON resource from our internal API.
 *
 * This abstracts the common tasks of parameterizing fetch, pulling relevant
 * data out of the API wrapper, and error handling.
 */
async function apiFetch(method, path, queryParams, body) {
  const options = {
    method,
    // Ensure we don't send cookies to another domain
    credentials: 'same-origin',
    // Ensure we don't leak the CSRF token to another domain
    mode: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': cookies.get(DJANGO_CSRF_COOKIE_NAME),
    },
  }

  const windowQueryParams = getQueryParams(window.location.search)
  if (windowQueryParams.disable_staff_mode) {
    if (!queryParams) {
      queryParams = {}
    }
    queryParams = {
      ...queryParams,
      disable_staff_mode: windowQueryParams.disable_staff_mode,
    }
  }

  const apiPath = `${path}${queryParams ? formatQueryString(queryParams) : ''}`
  if (body) {
    options.body = JSON.stringify(body)
  }

  const res = await fetch(apiPath, options)

  if (res.redirected) {
    throw new ApiRedirectError(res.url, res)
  }

  let json
  try {
    json = await res.json()
  } catch (e) {
    // Server sent back HTML for some reason
    const err = new ApiError('Server error', res)
    // Only log 5xx errors to Sentry; 4xx should generally be handled
    if (res.status >= 500) {
      logError(err)
    }
    throw err
  }
  if (!res.ok || json.error) {
    const errMsg = json.error
      ? typeof json.error === 'string'
        ? json.error
        : json.error.message
      : String(res.status)
    const code = json.error ? json.error.code : null
    const extraData = json.error ? json.error.extra_data : null
    // $FlowFixMe: Flow doesn't know that all the errors are strings, but they are
    const fieldErrors = Object.values(omit(json.error, 'message'))
    const err = new ApiError(errMsg, res, json, code, extraData, fieldErrors)
    // Only log 5xx errors to Sentry; 4xx should generally be handled
    if (res.status >= 500) {
      logError(err)
    }
    throw err
  }
  return json
}

/** apiFetch, except extract the `data` object from the ResponseData */
async function apiFetchData(method, path, queryParams, body) {
  const responseData = await apiFetch(method, path, queryParams, body)
  return responseData.data
}

function get(path, queryParams = null) {
  return apiFetchData('GET', path, queryParams)
}

function post(path, body = {}) {
  return apiFetchData('POST', path, null, body)
}

function put(path, body = {}) {
  return apiFetchData('PUT', path, null, body)
}

function del(path, body = {}) {
  return apiFetchData('DELETE', path, null, body)
}

function patch(path, body = {}) {
  return apiFetchData('PATCH', path, null, body)
}

function getResponseCacheKey(path, queryParams) {
  return `${path}${formatQueryString(queryParams || {})}`
}

async function fetchScreenResponse(
  path,
  queryParams = null,
  cacheResponse = false
) {
  const cacheKey = getResponseCacheKey(path, queryParams)
  if (SCREEN_RESPONSE_CACHE.has(cacheKey) && cacheResponse) {
    return SCREEN_RESPONSE_CACHE.get(cacheKey)
  }
  const response = await apiFetch('GET', path, queryParams, null)
  if (cacheResponse) {
    upsertScreenResponse(cacheKey, response)
  }
  return response
}

/**
 * Manually set the page cache.
 * Meant to be called by withScreenData when loading embedded data
 */
function upsertScreenResponse(cacheKey, response) {
  SCREEN_RESPONSE_CACHE.set(cacheKey, response)
}

// TODO(mime/jared): this is a wrapper around the feed for now.
// We should make this a separate API endpoint longterm.
async function getEventsFromFeedForCarousel(filterParamsPartial, organization) {
  const queryParams = filterParamsPartialToQueryParams(filterParamsPartial, {
    per_page: 6,
    page: 1,
  })
  const path = organization
    ? getOrganizationFeedUrl(organization).pathname
    : getMobilizeFeedUrl().pathname
  const response = await fetchScreenResponse(path, queryParams, true) // cache response
  return response.data
}

/* ApiEventsView */
function createEvent(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/events/`, body)
}

/* ApiEventView */
function updateEvent(orgSlug, eventId, body) {
  return put(`/_/api/organization/${orgSlug}/events/${eventId}/`, body)
}

/* ApiUpdateTimeslotView */
function updateTimeslot(orgSlug, timeslotId, body) {
  return patch(`/_/api/organization/${orgSlug}/timeslots/${timeslotId}/`, body)
}

/* ApiEventView */
function updateEventOwner(orgSlug, eventId, ownerId) {
  const body = {creator_id: ownerId}
  return patch(`/_/api/organization/${orgSlug}/events/${eventId}/`, body)
}

/* ApiReviewEventView */
function reviewEvents(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/events/review/`, body)
}

/* ApiParticipationTimeslotRecommendationsView */
function getTimeslotRecommendationsForParticipation(participationId) {
  return get(
    `/_/api/participation/${participationId}/recommendations/timeslots/`
  )
}

/* ApiOrganizationView */
function updateOrganization(orgSlug, body) {
  return put(`/_/api/organization/${orgSlug}/`, body)
}

// Prefer over updateOrganization for small changes to the Organization model.
/* ApiOrganizationView */
async function patchUpdateOrganization(orgSlug, body) {
  return patch(`/_/api/organization/${orgSlug}/`, body)
}

/* ApiUpdateEventCampaignView */
async function updateEventCampaign(orgSlug, eventCampaignId, body) {
  return put(
    `/_/api/organization/${orgSlug}/event-campaign/${eventCampaignId}/`,
    body
  )
}

/* ApiCreateEventCampaignView */
async function createEventCampaign(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/event-campaign/`, body)
}

/* ApiUserInitiatedMessageView */
async function sendUserInitiatedMessage(orgSlug, body) {
  const {user_initiated_message_id} = await post(
    `/_/api/organization/${orgSlug}/user-initiated-message/`,
    body
  )
  return user_initiated_message_id
}

/* ApiEmailContentReportView */
async function submitEmailContentReport(email_uuid, body) {
  return put(`/_/api/report_email/${email_uuid}/`, body)
}

/* ApiEventContentReportView */
async function submitEventContentReport(event_id, body) {
  return put(`/_/api/report_event/${event_id}/`, body)
}

// This is a POST request in order to support request bodies that are above the
// GET request url size limit.
/* ApiUserInitiatedMessageRecipientCountView */
async function fetchUserInitiatedMessageRecipientCount(orgSlug, body) {
  const {recipient_count} = await post(
    `/_/api/organization/${orgSlug}/user-initiated-message/fetch-recipient-count/`,
    body
  )
  return recipient_count
}

/* ApiUserInitiatedMessageRecipientCountView */
async function fetchUserInitiatedMessageRecipientDetails(orgSlug, body) {
  return await post(
    `/_/api/organization/${orgSlug}/user-initiated-message/fetch-recipient-count/`,
    body
  )
}

/* ApiOrganizationMembershipView */
function addToOrganizationMembership(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/users/`, body)
}

/* ApiOrganizationMembershipView */
function updateOrganizerMembership(orgSlug, userId, body) {
  return patch(`/_/api/organization/${orgSlug}/users/${userId}/`, body)
}

/* ApiUpdateParticipationView */
function updateParticipation(orgSlug, participationId, body) {
  return patch(
    `/_/api/organization/${orgSlug}/participations/${participationId}/`,
    body
  )
}

/* ApiOrganizationMembershipView */
function deleteFromOrganizationMembership(orgSlug, userId) {
  return del(`/_/api/organization/${orgSlug}/users/${userId}/`)
}

/* ApiPromoteOrganizationView */
function addOrgPromotion(orgSlug, promotedOrgSlug, context) {
  return put(`/_/api/organization/${orgSlug}/promote/${promotedOrgSlug}/`, {
    promotion_context: context,
  })
}

/* ApiPromoteOrganizationView */
function removeOrgPromotion(orgSlug, promotedOrgSlug) {
  return del(`/_/api/organization/${orgSlug}/promote/${promotedOrgSlug}/`)
}

/* ApiCreateSingleEventPromotionView */
function promoteEventById(eventId, orgIds, context) {
  return post(`/_/api/events/${eventId}/promote/`, {
    organization_ids: orgIds,
    promotion_context: context,
  })
}

/* ApiOrgPromotionRequestView */
function requestPromotion(orgSlug, targetOrgSlug) {
  return put(
    `/_/api/organization/${orgSlug}/promotion_request/${targetOrgSlug}/`
  )
}

/* ApiPromotionFilterView */
function updatePromotionFilters(orgSlug, filters) {
  return put(`/_/api/organization/${orgSlug}/promotions/filters/`, filters)
}

/* ApiGetEligibleOrgsForEventPromotionView */
function getEligibleOrgsForPromotion(eventId) {
  return get(`/_/api/events/${eventId}/eligible-orgs-for-promotion/`)
}

/* ApiFeatureEventView */
function featureEvent(orgSlug, eventId, body) {
  return put(`/_/api/organization/${orgSlug}/events/${eventId}/feature/`, body)
}

/* ApiExcludeEventView */
function excludeEvent(orgSlug, eventId, body) {
  return put(`/_/api/organization/${orgSlug}/events/${eventId}/exclude/`, body)
}

/* PersonScheduleView */
function updateVolunteerScheduleParticipation(
  participationId,
  schedule_url,
  body
) {
  return patch(`${schedule_url}${participationId}/`, body)
}

/* EventSignupView */
function signUpByEventId(eventId, body) {
  return post(`/_/api/events/${eventId}/participations/`, body)
}

/* ApiCreateSignupView */
function signUpByOrgAndEventIdFromDashboard(orgSlug, eventId, body) {
  return post(
    `/_/api/organization/${orgSlug}/events/${eventId}/participations/`,
    body
  )
}

/* EventAddGroupSignupMembersView */
function addGroupMemberSignupByEventId(eventId, body) {
  return post(`/_/api/events/${eventId}/participations/group-members/`, body)
}

/* GroupSignupMembersView */
function addGroupMemberSignup(leaderParticipationId, body) {
  return post(
    `/_/api/participation/${leaderParticipationId}/group-members/`,
    body
  )
}

/* GroupSignupMembersView */
function updateGroupMember(leaderParticipationId, memberParticipationId, body) {
  return patch(
    `/_/api/participation/${leaderParticipationId}/group-members/${memberParticipationId}/`,
    body
  )
}

/* OrganizationLeadsView */
function submitOrgLead(orgSlug, lead) {
  return post(`/_/api/organization/${orgSlug}/leads/`, lead)
}

/* OrganizationAffiliationsView */
function affiliateWithOrg({
  orgSlug,
  person,
  trackingParams,
  promotionalSmsOptIn,
  commitToHost,
  childOrgAffiliationId,
  affirmMembership,
  turnstileToken,
}) {
  const {
    email,
    zip,
    firstName,
    lastName,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
  } = person
  return put(`/_/api/organization/${orgSlug}/affiliations/`, {
    email,
    zipcode: zip,
    first_name: firstName,
    last_name: lastName,
    address_line1: addressLine1,
    address_line2: addressLine2,
    city,
    state,
    phone,
    ...(trackingParams || {}),
    promotional_sms_opt_in: promotionalSmsOptIn,
    commit_to_host: commitToHost,
    affirm_membership: affirmMembership,
    child_org_affiliation_id: childOrgAffiliationId,
    cf_turnstile_token: turnstileToken,
  })
}

/* ApiOrganizationAffiliationView */
function updateOrgAffiliation(orgSlug, userId, is_blocked) {
  return patch(`/_/api/organization/${orgSlug}/affiliations/${userId}/`, {
    is_blocked,
  })
}

/* UserUpdateAffiliationView */
function updateUserAffiliation(userId, orgId, is_unfollowed) {
  return patch(`/_/api/user/${userId}/affiliations/${orgId}/`, {is_unfollowed})
}

/* ApiEventView */
function deleteEvent(orgSlug, eventId, body) {
  return del(`/_/api/organization/${orgSlug}/events/${eventId}/`, body)
}

/* UserChangePasswordView */
function changeUserPassword(body) {
  return put(`/_/api/user/password/`, body)
}

/* UpdateUserView */
function updateUserDetails(body) {
  return put('/_/api/user/details/', body)
}

/* UserChangeEmailView */
function changeUserEmail(body) {
  return put(`/_/api/user/email/`, body)
}

/* PeriscopeDrilldownUrl */
async function getPeriscopeDrilldownUrl(dashboardID, dashboardFilters) {
  const {periscope_iframe_url} = await get(
    `/_/api/periscope_drilldown/${dashboardID}/`,
    {
      filter_name: dashboardFilters[0].filter_name,
      column_value: dashboardFilters[0].column_value,
    }
  )
  return periscope_iframe_url
}

/* UserChangeEmailNotificationSettingView */
function changeUserEmailNotificationSetting(category, body) {
  return put(`/_/api/user/notifications/email/${category}`, body)
}

async function getS3SignedURL(fileName, fileMimeType, resource) {
  const {url} = await get('/_/api/s3/publicimage/', {
    file_name: fileName,
    file_mimetype: fileMimeType,
    resource: resource,
  })
  if (!url) throw new Error('Unable to upload file')
  return url
}

function uploadPublicFileToS3(url, body) {
  return fetch(url, {
    method: 'PUT',
    body,
    headers: {'x-amz-acl': 'public-read'},
  })
}

/* LogoutUserView */
function logoutCurrentUser() {
  return get('/_/api/logout_user/')
}

/* ShiftExportView */
function initializeShiftExport(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/shifts/export/`, body)
}

/* PeopleExportView */
function initializePeopleExport(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/people/export/`, body)
}

/* UsersExportView */
function initializeUsersExport(orgSlug) {
  return post(`/_/api/organization/${orgSlug}/users/export/`)
}

/* FilteredEventsExportView */
function intitializeFilteredEventsExport(orgSlug, tab, filterParams) {
  return post(
    `/_/api/organization/${orgSlug}/events/export/${tab}/`,
    mapObject(filterParams, (val) => val || undefined)
  )
}

/* EventsExportView */
function initializeEventsExport(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/events/export/`, body)
}

/* EventParticipationsExportView */
function initializeEventParticipationsExport(orgSlug, eventId) {
  return post(
    `/_/api/organization/${orgSlug}/events/${eventId}/participations/export/`
  )
}

/* TimeslotParticipationsExportView */
function initializeTimeslotParticipationsExport(orgSlug, timeslotId) {
  return post(
    `/_/api/organization/${orgSlug}/timeslots/${timeslotId}/participations/export/`
  )
}

/* ContactHostView */
function sendContactHostRequestBody(eventId, body) {
  return post(`/events/${eventId}/contact-host/`, body)
}

/* EventVanResyncView */
function resyncToVan(orgSlug, eventId) {
  return post(`/_/api/organization/${orgSlug}/events/${eventId}/van-resync/`)
}

/* EventVanModelsView */
function getEventVanModels(orgSlug, eventId) {
  return get(`/_/api/organization/${orgSlug}/events/${eventId}/van-models/`)
}

/* EventVanModelsView */
function deleteEventVanModels(orgSlug, eventId) {
  return del(`/_/api/organization/${orgSlug}/events/${eventId}/van-models/`)
}

/* TimeslotVanModelsView */
function getTimeslotVanModels(orgSlug, timeslotId) {
  return get(
    `/_/api/organization/${orgSlug}/timeslots/${timeslotId}/van-models/`
  )
}

/* TimeslotVanModelsView */
function deleteTimeslotVanModels(orgSlug, timeslotId) {
  return del(
    `/_/api/organization/${orgSlug}/timeslots/${timeslotId}/van-models/`
  )
}

/* ApiOrganizationVanView */
function updateVanConfig(orgSlug, body) {
  return put(`/_/api/organization/${orgSlug}/van-config/`, body)
}

/* ApiSelfServiceVanView */
function createVanCommittee(orgSlug, apiKey) {
  return post(`/_/api/organization/${orgSlug}/van-committee/`, {
    api_key: apiKey,
  })
}

/* OrganizationVanEventTypeInfoView */
async function getVanEventTypeInfo(orgSlug) {
  const {van_event_types: vanEventTypes} = await get(
    `/_/api/organization/${orgSlug}/van-event-type-info/`
  )
  return vanEventTypes
}

/* OrganizationVanEventActivistCodeInfoView */
async function getVanActivistCodes(orgSlug) {
  const {van_activist_codes: vanActivistCodes} = await get(
    `/_/api/organization/${orgSlug}/van-event-activist-codes-info/`
  )
  return vanActivistCodes
}

/* OrganizationActionkitCampaignInfoView */
async function getActionkitCampaignInfo(orgSlug, campaignId) {
  const queryParams = {}
  if (campaignId) {
    queryParams.id = campaignId
  }
  const {actionkit_campaigns: actionkitCampaigns} = await get(
    `/_/api/organization/${orgSlug}/actionkit-campaign-info/`,
    queryParams
  )
  return actionkitCampaigns
}

/* OrganizationActionkitEventPageInfoView */
async function _getActionkitEventCreatePageInfo(orgSlug, queryParams) {
  const {actionkit_event_create_pages: actionkitEventCreatePages} = await get(
    `/_/api/organization/${orgSlug}/actionkit-event-create-page-info/`,
    queryParams
  )
  return actionkitEventCreatePages || []
}
function getActionkitEventCreatePageInfoByName(orgSlug, name) {
  return _getActionkitEventCreatePageInfo(orgSlug, {name})
}
function getActionkitEventCreatePageInfoByIds(orgSlug, ids) {
  return _getActionkitEventCreatePageInfo(orgSlug, {id: ids})
}

/* OrganizationActionkitEventPageInfoView */
async function _getActionkitEventSignupPageInfo(orgSlug, queryParams) {
  const {actionkit_event_signup_pages: actionkitEventSignupPages} = await get(
    `/_/api/organization/${orgSlug}/actionkit-event-signup-page-info/`,
    queryParams
  )
  return actionkitEventSignupPages || []
}
function getActionkitEventSignupPageInfoByName(orgSlug, name) {
  return _getActionkitEventSignupPageInfo(orgSlug, {name})
}
function getActionkitEventSignupPageInfoByIds(orgSlug, ids) {
  return _getActionkitEventSignupPageInfo(orgSlug, {id: ids})
}

/* ApiOrganizationEventTypesView */
async function getEventTypes(orgSlug) {
  const {event_types: eventTypes} = await get(
    `/_/api/organization/${orgSlug}/event-types/`
  )
  return eventTypes
}

/* ApiOrganizationOrgsForFilteringView or ApiMainFeedOrgsForFilteringView */
async function getOrgsForFiltering(orgSlug) {
  const {shown_orgs: shownOrgs} = orgSlug
    ? await get(`/_/api/organization/${orgSlug}/orgs-for-filtering/`)
    : await get('/_/api/main-feed-orgs-for-filtering/')
  const map = new Map()
  Object.keys(shownOrgs).forEach((key) =>
    map.set(parseInt(key, 10), shownOrgs[key])
  )
  return map
}

/* ApiRestoreDeletedEventView */
async function restoreDeletedEvent(orgSlug, eventId) {
  return post(
    `/_/api/organization/${orgSlug}/events/${eventId}/restore-deleted/`
  )
}

/* PasswordlessLoginApiView */
async function requestLoginLink(email, next) {
  return post('/_/api/login/email/', {
    email,
    next,
  })
}

/* RegisterUserAndPasswordlessLoginApiView */
async function registerUserAndRequestLoginLink(body) {
  return post('/_/api/user/register/', body)
}

/* ApiUnauthenticatedRequestEventVerificationEmailView */
async function requestEventVerificationEmail(eventId) {
  return post(`/_/api/request_event_verification_email_v2/${eventId}/`)
}

// NB(avi): For backwards compatibility. Remove after a couple deploys
/* BackwardsCompatibleApiUnauthenticatedRequestEventVerificationEmailView */
async function backwardsCompatibleRequestEventVerificationEmail(eventUuid) {
  return post(`/_/api/request_event_verification_email/${eventUuid}/`)
}

/* ApiSendEventVerificationEmailView */
async function sendEventVerificationEmail(orgSlug, eventId) {
  return post(
    `/_/api/organization/${orgSlug}/events/${eventId}/send_event_verification_email/`
  )
}

/* LoginWithFacebookView */
async function loginWithFacebook(facebook_user_id, facebook_access_token) {
  return post('/_/api/login/facebook/', {
    facebook_user_id,
    facebook_access_token,
  })
}

/* LoginWithGoogleView */
async function loginWithGoogle(google_auth_code) {
  return post('/_/api/login/google/', {
    google_auth_code,
  })
}

/* ConnectGoogleView */
async function connectUserGoogle(google_auth_code) {
  return post('/_/api/user/google/', {
    google_auth_code,
  })
}

/* ConnectGoogleView */
async function disconnectUserGoogle() {
  return del('/_/api/user/google/')
}

/* ConnectFacebookView */
async function connectUserFacebook(facebook_user_id, facebook_access_token) {
  return post('/_/api/user/facebook/', {
    facebook_user_id,
    facebook_access_token,
  })
}

/* FacebookTokenView */
async function getTokenFromFacebookDetails(
  facebook_user_id,
  facebook_access_token,
  domain
) {
  return post('/_/api/facebook/token/', {
    facebook_user_id,
    facebook_access_token,
    domain,
  })
}

/* TokenRedemptionApiView */
async function loginWithToken(user_id, ott) {
  return post('/_/api/login/token/', {user_id, ott})
}

// TODO(nate): remove this when we're sure it's not needed
async function sendTestEmail(body) {
  return post(`/_/admin/api/email-templates-test-send/`, body)
}

/* ConnectFacebookView */
async function disconnectUserFacebook() {
  return del('/_/api/user/facebook/')
}

/* ApiHostCreateEventView */
async function hostCreateEvent(orgSlug, hostedEvent) {
  return post(`/_/api/organization/${orgSlug}/events/host/`, hostedEvent)
}

/* ApiHostUpdateEventView */
async function hostUpdateEvent(orgSlug, eventId, updateHostedEventRequest) {
  return put(
    `/_/api/organization/${orgSlug}/events/${eventId}/host/`,
    updateHostedEventRequest
  )
}

/* OrganizationOrganizersView */
async function getOrganizationMembers(
  orgSlug,
  searchString,
  includeOrganizers
) {
  const queryParams = {}
  if (searchString) {
    queryParams.filter = searchString
  }
  if (includeOrganizers.length > 0) {
    queryParams.include = includeOrganizers
  }
  return get(`/_/api/organization/${orgSlug}/organizers/`, queryParams)
}

/* ApiTimezoneView */
async function getTimezoneFromLatLon(latLon) {
  const {timezone} = await get('/_/api/timezone/', latLon)
  return timezone
}

/* ApiUpdateEventCoOwnersView */
async function updateEventCoOwners(eventId, orgIds, orgSlug) {
  const updateBody = {
    org_ids: orgIds,
  }
  return put(
    `/_/api/organization/${orgSlug}/events/${eventId}/event-co-owners/`,
    updateBody
  )
}

/* ApiTimeslotParticipationSearchView */
async function searchParticipantsByTimeslot(
  orgSlug,
  timeslotId,
  searchTerm,
  page
) {
  return get(
    `/_/api/organization/${orgSlug}/timeslot/${timeslotId}/participations/`,
    {search: searchTerm, page}
  )
}

/* ApiOrganizationSearchView */
async function searchPromotableOrganizations(
  orgSlug,
  searchTerm,
  searchFilters,
  page
) {
  return get(`/_/api/organization/${orgSlug}/organizations/promotable/`, {
    search: searchTerm,
    org_types: searchFilters.orgTypes,
    states: searchFilters.locations,
    race_types: searchFilters.raceTypes,
    page,
  })
}

/* ApiVolunteerSearchView */
async function searchVolunteers(orgSlug, searchTerm, page, searchFilters) {
  return get(`/_/api/organization/${orgSlug}/volunteers/`, {
    search: searchTerm,
    page,
    states: searchFilters.state,
    zipcode: searchFilters.zipcode,
    exact_zip: searchFilters.exact_zip,
    referral_comparison_attr: searchFilters.referral_comparison_attr,
    referral_comparison_num: searchFilters.referral_comparison_num,
    total_shifts_comparison_attr: searchFilters.total_shifts_comparison_attr,
    total_shifts_comparison_num: searchFilters.total_shifts_comparison_num,
  })
}

/* ApiOrganizerSearchView */
async function searchOrganizers(orgSlug, searchTerm, permissionTier, page) {
  // without the explcit typing flow won't let me add permission_tier
  const params = {
    search: searchTerm,
    page,
  }
  if (permissionTier) {
    params.permission_tier = permissionTier
  }
  return get(`/_/api/organization/${orgSlug}/search-organizers/`, params)
}

/* AdminShell */
export async function adminShell(code) {
  return post('/_/admin/shell/', {code})
}

/* ApiSelfServiceOrganizationView */
async function createSelfServiceOrganization(body) {
  return post('/_/api/self_service/organization/create/', body)
}

/* ApiSelfServiceChildOrganizationView */
async function createSelfServiceChildOrganization(org_id, body) {
  return post(`/_/api/self_service/organization/${org_id}/create/`, body)
}

/* ApiCustomSignupFieldsView */
function createOrganizationCustomSignupField(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/custom-signup-fields/`, body)
}

/* ApiCustomSignupFieldsView */
function updateOrganizationCustomSignupField(
  orgSlug,
  customSignupFieldId,
  body
) {
  return put(
    `/_/api/organization/${orgSlug}/custom-signup-fields/${customSignupFieldId}/`,
    body
  )
}

/* ApiCustomSignupFieldsView */
function deleteOrganizationCustomSignupField(orgSlug, customSignupFieldId) {
  return del(
    `/_/api/organization/${orgSlug}/custom-signup-fields/${customSignupFieldId}/`
  )
}

// Fundraiser config API.

/* ApiFundraiserConfigsView */
function createOrganizationFundraiserConfig(orgSlug, body) {
  return post(`/_/api/organization/${orgSlug}/fundraiser-configs/`, body)
}

/* ApiFundraiserConfigsView */
function updateOrganizationFundraiserConfig(orgSlug, fundraiserConfigId, body) {
  return put(
    `/_/api/organization/${orgSlug}/fundraiser-configs/${fundraiserConfigId}/`,
    body
  )
}

/* ApiFundraiserConfigsView */
function deleteOrganizationFundraiserConfig(orgSlug, fundraiserConfigId) {
  return del(
    `/_/api/organization/${orgSlug}/fundraiser-configs/${fundraiserConfigId}/`
  )
}

// Salesforce API routes.

/* ApiSalesforceConfigView */
function updateOrganizationSalesforceConfig(orgSlug, body) {
  return put(`/_/api/organization/${orgSlug}/salesforce-config/`, body)
}

/* ApiSalesforceConfigView */
function deleteOrganizationSalesforceConfig(orgSlug) {
  return del(`/_/api/organization/${orgSlug}/salesforce-config/`)
}

// Zoom

/* ApiZoomConfigView */
function updateOrganizationZoomConfig(orgSlug, body) {
  return put(`/_/api/organization/${orgSlug}/zoom-config/`, body)
}

/* ApiZoomConfigView */
function deleteOrganizationZoomConfig(orgSlug) {
  return del(`/_/api/organization/${orgSlug}/zoom-config/`)
}

/* ZoomMeetingInfoView */
function getZoomMeetingInfo(orgSlug, zoomMeetingId) {
  return get(`/_/api/organization/${orgSlug}/zoom-meeting-info/`, {
    meeting_id: zoomMeetingId,
  })
}

/* ApiOrganizationLandingPageSuggestionsView */
function getLandingPageSuggestions(orgSlug) {
  return get(`/_/api/organization/${orgSlug}/landing-page-suggestions/`)
}

/* ApiOrganizationSuggestionsForPromotionView */
function getOrgSuggestionsForPromotion({
  promotingOrgSlug,
  justPromotedOrgSlug,
  page,
  per_page,
}) {
  return get(
    `/_/api/organization/${promotingOrgSlug}/promotion-suggestions/${justPromotedOrgSlug}/`,
    {page, per_page}
  )
}

/* ApiMarkersForFeedMapView */
function getMarkersForFeedMap(orgSlug, filterParams) {
  return get(`/_/api/organization/${orgSlug}/feed-map-markers/`, filterParams)
}

/* ApiOrganizationSuggestionsView */
async function getOrgSuggestionsForCarousel(orgSlug, filterParamsPartial) {
  const filterParams = filterParamsPartialToQueryParams(filterParamsPartial)
  return get(`/_/api/organization/${orgSlug}/org-suggestions/`, filterParams)
}

/* ApiTrendingPromotableEventsView */
async function getTrendingEventsForCarousel(orgSlug) {
  return get(`/_/api/organization/${orgSlug}/trending-promotable-events/`)
}

/* ApiGetAvailableOwningGroupsView */
async function getAvailableOwningGroups(orgSlug, hostIds) {
  return get(`/_/api/organization/${orgSlug}/available-owning-groups/`, {
    host_ids: hostIds,
  })
}

/* ApiOrganizationChildrenView */
async function fetchChildOrganizations(orgSlug, params) {
  return get(`/_/api/organization/${orgSlug}/children`, params)
}

async function getAhaToken(user_id) {
  return get(`/_/api/aha_token/${user_id}`)
}

const api = {
  addOrgPromotion,
  addToOrganizationMembership,
  affiliateWithOrg,
  apiFetch, // NB: Only exported for testing; probably shouldn't use directly
  changeUserEmail,
  changeUserEmailNotificationSetting,
  changeUserPassword,
  connectUserFacebook,
  connectUserGoogle,
  createEvent,
  createEventCampaign,
  createSelfServiceOrganization,
  createSelfServiceChildOrganization,
  createOrganizationCustomSignupField,
  createOrganizationFundraiserConfig,
  deleteEvent,
  deleteEventVanModels,
  deleteTimeslotVanModels,
  deleteFromOrganizationMembership,
  deleteOrganizationCustomSignupField,
  deleteOrganizationFundraiserConfig,
  deleteOrganizationSalesforceConfig,
  deleteOrganizationZoomConfig,
  disconnectUserFacebook,
  disconnectUserGoogle,
  excludeEvent,
  featureEvent,
  updateParticipation,
  fetchScreenResponse,
  fetchUserInitiatedMessageRecipientCount,
  fetchUserInitiatedMessageRecipientDetails,
  getActionkitCampaignInfo,
  getActionkitEventCreatePageInfoByName,
  getActionkitEventCreatePageInfoByIds,
  getActionkitEventSignupPageInfoByName,
  getActionkitEventSignupPageInfoByIds,
  getAvailableOwningGroups,
  fetchChildOrganizations,
  getEligibleOrgsForPromotion,
  getEventsFromFeedForCarousel,
  getEventTypes,
  getOrganizationMembers,
  getOrgSuggestionsForPromotion,
  getOrgsForFiltering,
  getPeriscopeDrilldownUrl,
  getMarkersForFeedMap,
  getResponseCacheKey,
  getS3SignedURL,
  getLandingPageSuggestions,
  getOrgSuggestionsForCarousel,
  getTrendingEventsForCarousel,
  getTimezoneFromLatLon,
  getEventVanModels,
  getTimeslotRecommendationsForParticipation,
  getTimeslotVanModels,
  getTokenFromFacebookDetails,
  getVanActivistCodes,
  getVanEventTypeInfo,
  getZoomMeetingInfo,
  hostCreateEvent,
  hostUpdateEvent,
  initializeEventParticipationsExport,
  initializeTimeslotParticipationsExport,
  initializePeopleExport,
  initializeUsersExport,
  initializeEventsExport,
  intitializeFilteredEventsExport,
  initializeShiftExport,
  loginWithFacebook,
  loginWithGoogle,
  loginWithToken,
  logoutCurrentUser,
  patchUpdateOrganization,
  promoteEventById,
  removeOrgPromotion,
  requestEventVerificationEmail,
  backwardsCompatibleRequestEventVerificationEmail,
  requestLoginLink,
  requestPromotion,
  registerUserAndRequestLoginLink,
  restoreDeletedEvent,
  resyncToVan,
  reviewEvents,
  sendContactHostRequestBody,
  searchOrganizers,
  searchParticipantsByTimeslot,
  searchPromotableOrganizations,
  searchVolunteers,
  sendEventVerificationEmail,
  sendTestEmail,
  sendUserInitiatedMessage,
  setUpVanIntegration: createVanCommittee,
  signUpByEventId,
  signUpByOrgAndEventIdFromDashboard,
  addGroupMemberSignupByEventId,
  addGroupMemberSignup,
  submitEmailContentReport,
  submitEventContentReport,
  submitOrgLead,
  updateOrganizationCustomSignupField,
  updateOrganizationFundraiserConfig,
  updateOrganizationSalesforceConfig,
  updateOrganizationZoomConfig,
  updateEvent,
  updateEventCampaign,
  updateEventOwner,
  updateEventCoOwners,
  updateGroupMember,
  updateTimeslot,
  updateOrgAffiliation,
  updateOrganization,
  updateOrganizerMembership,
  updatePromotionFilters,
  updateUserAffiliation,
  updateUserDetails,
  updateVanConfig,
  updateVolunteerScheduleParticipation,
  uploadPublicFileToS3,
  upsertScreenResponse,
  getAhaToken,
}
export default api
