import {Button, CardModal, CheckboxField, Typography} from 'components'
import {useContext, useState} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import {EventSuggestionType} from 'app/enums'
import EventSuggestions from 'dashboard/components/EventSuggestions'
import {F} from 'util/i18n'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'

const SectionHeaderWrapper = styled.div`
  margin-top: ${styles.space.xl};
  margin-bottom: ${styles.space.m};
`

const CustomizeButtonWrapper = styled.div`
  margin-top: ${styles.space.m};
`

export default function GroupSuggestedEventsLinkingSettings({
  event,
  onFieldChange,
}) {
  const [showSuggestionModal, setShowSuggestionModal] = useState(false)
  const orgContext = useContext(CurrentOrganizationContext)
  const organization = orgContext?.currentOrganization
  const groupSuggestedEvents = event.group_suggested_events || []
  const eventSuggestions = groupSuggestedEvents.map((event) => ({
    suggestion_type: EventSuggestionType.EVENT_TO_EVENT,
    suggested_event_id: event.id,
    suggested_filter_param_query_string: null,
  }))
  const onSubmit = (suggestions) => {
    onFieldChange(null, {
      name: 'group_suggested_events',
      value: suggestions.map((suggestion) => ({
        id: suggestion.suggested_event_id,
      })),
    })
    setShowSuggestionModal(false)
  }

  return (
    <>
      <SectionHeaderWrapper>
        <Typography variant="h2">
          <F
            defaultMessage="{orgName} events and actions linked to this group"
            values={{orgName: organization?.name}}
          />
        </Typography>
      </SectionHeaderWrapper>
      <CheckboxField
        name="group_should_include_all_events_by_hosts_by_default"
        type="switch"
        label={
          <F defaultMessage="Automatically link events and actions created by group admins" />
        }
        hint={
          <F defaultMessage="Edit auto-linked events to remove them from this group" />
        }
        checked={!!event.group_should_include_all_events_by_hosts_by_default}
        onChange={({name, value}) => {
          onFieldChange(null, {name, value})
        }}
      />
      <CustomizeButtonWrapper>
        <Button
          padding="none"
          type="button"
          link
          onClick={() => setShowSuggestionModal(true)}
        >
          <F defaultMessage="Link additional events" />
        </Button>{' '}
        <F
          defaultMessage="({numEvents, plural, one {# event} other {# events}} linked)"
          values={{numEvents: eventSuggestions.length}}
        />
      </CustomizeButtonWrapper>
      {showSuggestionModal && (
        <CardModal
          isOpen
          headerAsTitle
          onRequestClose={() => setShowSuggestionModal(false)}
          header={
            event.name ? (
              <F
                defaultMessage="Link additional events to {groupName}"
                values={{groupName: event.name}}
              />
            ) : (
              <F defaultMessage="Link additional events" />
            )
          }
        >
          <EventSuggestions
            eventSuggestions={eventSuggestions}
            onSubmit={onSubmit}
            onCancel={() => setShowSuggestionModal(false)}
            organization={organization}
            paddingTop={styles.space.l}
            submitButtonText="Save"
            groupSuggestions
            minimumInputsToRender={1}
          />
        </CardModal>
      )}
    </>
  )
}
