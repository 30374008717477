import {Component} from 'react'
import analytics from 'analytics'
import {getDisplayName} from 'util/string'
import hoistNonReactStatics from 'hoist-non-react-statics'

export default function withDashboardAnalytics(Wrapped) {
  // Should be nested inside withScreenData HoC to access the props it supplies
  class withDashboardAnalytics extends Component {
    static displayName = `withDashboardAnalytics(${getDisplayName(Wrapped)})`

    componentDidMount() {
      if (!this.props.data.user) {
        return
      }
      const {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
      } = this.props.data.user
      const segmentUserData = {
        id,
        firstName,
        lastName,
        email,
      }
      analytics.maybeIdentifyUser(segmentUserData)
    }

    render() {
      return <Wrapped {...this.props} />
    }
  }

  return hoistNonReactStatics(withDashboardAnalytics, Wrapped)
}
