import moment from 'vendor/moment'
import {orgFlagIsActive} from 'util/organization'

export const isZoomEnabled = (organization) =>
  orgFlagIsActive(organization, 'enable_zoom_integration') &&
  organization?.is_zoom_integration_active

export const EMPTY_VIRTUAL_JOIN_DETAILS = {
  privateDetails: null,
  virtualJoinUrl: null,
  zoomMeetingId: null,
  zoomMeetingType: null,
}

export const getTimeslotFormattedDateAndTime = (timeslot, event_timezone) => {
  const startMoment = moment(timeslot.starts_at_utc, moment.ISO_8601)
  const startFormattedString = startMoment
    .tz(event_timezone)
    .format('dddd, M/D/YYYY, h:mma')
  const endMoment = moment(timeslot.ends_at_utc, moment.ISO_8601)
  const endFormattedString = endMoment.tz(event_timezone).format('h:mma z')

  return `${startFormattedString}–${endFormattedString}`
}
