import './Colors.css'
import './App.css'

import {Component, Suspense, lazy, useContext, useEffect, useState} from 'react'
import {
  IntlProvider,
  isInternalLocale,
  setLocales,
  setupCreateIntl,
} from 'util/i18n'

import {ClassNames} from '@emotion/react'
import CurrentOrganizationContext from './CurrentOrganizationContext'
import CurrentUserContext from './CurrentUserContext'
import IntlMessageContext from './IntlMessageContext'
import Modal from 'react-modal'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import OrganizationThemeProvider from './OrganizationThemeProvider'
import PageError from './PageError'
import {Globals as ReactSpringGlobals} from 'react-spring'
import {Router} from 'react-router-dom'
import Routes from './Routes'
import {SnackbarProvider} from 'notistack'
import TrackingParamsContext from './TrackingParamsContext'
import analytics from 'analytics'
import clientVars from 'util/clientVars'
import {createBrowserHistory} from 'history'
import styles from 'components/styles'

// Use custom history to track page events (see http://kjh.pw/TvPtn)
const history = createBrowserHistory()
history.listen(() => {
  analytics.page()
  // We delete embedded data on page transition as well as in withScreenData(),
  // so that if for some reason we start on a page with embedded data that
  // doesn't use it, and navigate to a page without, we don't read that data by
  // mistake
  delete window.__MLZ_EMBEDDED_DATA__
})

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  componentDidMount() {
    // track initial page view. only gets called on page load. we don't use the default page track
    // in the snippet so we can do fb pixel tracking and other stuff
    analytics.page()

    Modal.setAppElement('.App')

    // Simplified approach from https://github.com/pmndrs/react-spring/issues/811 that doesn't
    // handle changes without reloading
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      ReactSpringGlobals.assign({skipAnimation: true})
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error})
  }

  render() {
    if (this.state.error) {
      return (
        <Providers minimal>
          <div className="App">
            <PageError error={this.state.error} />
          </div>
        </Providers>
      )
    }

    return (
      <Providers trackingParams={this.props.trackingParams}>
        <div className="App">
          <Routes {...this.props} />
          <Debug />
        </div>
      </Providers>
    )
  }
}

setLocales({
  defaultLocale: clientVars.default_locale,
  locales: clientVars.locales,
})

// This component holds all the various Context providers.
// See: https://reactjs.org/docs/context.html#updating-context-from-a-nested-component
// and the related setters we pass to Providers below.
//
// TODO(mime): usually we get our data via withScreenData but the exception
// here for now is the user/current_organization data, which cuts across the entire app.
// This is currently kept up-to-date with soft navigations via withScreenData/DataProvider
// updates to the CurrentUserContext/CurrentOrganizationContext context objects.
const Providers = ({children, trackingParams, minimal}) => {
  const initialUser = window.__MLZ_EMBEDDED_DATA__?.data?.user
  const initialCurrentOrganization =
    window.__MLZ_EMBEDDED_DATA__?.data?.current_organization
  const [user, setUser] = useState(initialUser)
  const [currentOrganization, setCurrentOrganization] = useState(
    initialCurrentOrganization
  )

  const userValue = {user, setUser}
  const orgValue = {
    currentOrganization,
    setCurrentOrganization,
  }

  if (minimal) {
    // the `minimal` rendering pathway is really only for one use case: the
    // PageError component. We intentionally short-circuit the
    // OrganizationThemeProvider with a null organization just to get styling to
    // work. Other than that, only the I18nProviders are needed for this page,
    // so we leave out the other providers.
    // (this could definitely use some revision at a future time)
    return (
      <I18nProviders>
        <OrganizationThemeProvider organization={null}>
          {children}
        </OrganizationThemeProvider>
      </I18nProviders>
    )
  }

  return (
    <I18nProviders>
      <TrackingParamsContext.Provider value={{trackingParams}}>
        <CurrentUserContext.Provider value={userValue}>
          <CurrentOrganizationContext.Provider value={orgValue}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <ClassNames>
                  {({css, cx}) => (
                    <SnackbarProvider
                      classes={{
                        base: css({
                          fontFamily: `${styles.typography.fontFamily} !important`,
                        }),
                      }}
                    >
                      {children}
                    </SnackbarProvider>
                  )}
                </ClassNames>
              </Router>
            </MuiPickersUtilsProvider>
          </CurrentOrganizationContext.Provider>
        </CurrentUserContext.Provider>
      </TrackingParamsContext.Provider>
    </I18nProviders>
  )
}

const I18nProviders = ({children}) => {
  const defaultLocale = clientVars.default_locale
  const [intlMessageConfig, setIntlMessageConfig] = useState({
    locale: window.__MLZ_EMBEDDED_DATA__?.data?.locale || clientVars.locale,
    i18nNamespace: window.__MLZ_EMBEDDED_DATA__?.data?.i18n_namespace,
  })
  const {locale, i18nNamespace} = intlMessageConfig
  const [messages, setMessages] = useState()
  useEffect(() => {
    let mounted = true
    loadI18nMessages({locale, i18nNamespace})
    return () => {
      mounted = false
    }

    async function loadI18nMessages({locale, i18nNamespace}) {
      // dynamically load messages based on which locale & namespace combination is currently required
      if (!isInternalLocale(locale)) {
        const loadedMessages = await import(
          `../messages/${
            locale + (i18nNamespace ? `/${i18nNamespace}` : '')
          }.json`
        )
        if (mounted) setMessages(loadedMessages)
      }
    }
  }, [locale, i18nNamespace])
  setupCreateIntl({locale, defaultLocale, messages}) // sets up non-React code for i18n support

  return (
    <IntlMessageContext.Provider
      value={{setIntlMessageConfig, intlMessageConfig}}
    >
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={locale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </IntlMessageContext.Provider>
  )
}

const Debug = () => {
  const {user} = useContext(CurrentUserContext)
  if (
    process.env.NODE_ENV !== 'development' &&
    (!user || !user.can_view_internal_only_features)
  ) {
    return null
  }

  const DebugComponent = lazy(() => import('internal/Debug'))
  return (
    <Suspense fallback={<span />}>
      <DebugComponent />
    </Suspense>
  )
}
