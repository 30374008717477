import {isEqual, omit} from 'util/common'
import {useContext, useEffect} from 'react'

import CurrentOrganizationContext from 'app/CurrentOrganizationContext'
import CurrentUserContext from 'app/CurrentUserContext'
import {Loader} from 'components'
import Page from 'app/Page'
import PageError from 'app/PageError'
import cv from 'util/clientVars'
import {getQueryParams} from 'util/url'
import styled from '@emotion/styled/macro'
import styles from 'components/styles'
// $FlowFixMe (mime): types need updating for latest react-router
import {useLocation} from 'react-router'
import useScreenData from './useScreenData'

const LoaderWrapper = styled.div`
  margin-top: ${styles.space.l};
  height: 100vh;
`

// Props internal to the DataProvider implementation. Make sure to add new props to internalPropsKeys

const internalPropsKeys = ['setLastSeenLoggedInAtToNow', 'setBuildCommit']

const DataProviderWithRestAPI = (props) => {
  const {data, error, metadata, isLoading} = useScreenData(props.screenOptions)

  return (
    <DataProvider
      {...props}
      data={data}
      error={error}
      metadata={metadata}
      isLoading={isLoading}
    />
  )
}

const DataProvider = ({
  screenOptions,
  Wrapped,
  setBuildCommit,
  setLastSeenLoggedInAtToNow,
  data,
  error,
  metadata,
  isLoading,
  ...props
}) => {
  const location = useLocation()
  const {currentOrganization, setCurrentOrganization} = useContext(
    CurrentOrganizationContext
  )
  const {user, setUser} = useContext(CurrentUserContext)

  useEffect(() => {
    if (error || isLoading) {
      return
    }

    document.title =
      metadata?.page_title ||
      data?.current_organization?.name ||
      cv.product_name
  })

  useEffect(() => {
    if (error || isLoading || !data) {
      return
    }

    if (data.user) {
      setLastSeenLoggedInAtToNow()
    }

    if (metadata) {
      setBuildCommit(metadata.build_commit)
    }

    if ((data.user && data.user.id) !== (user && user.id)) {
      setUser(data.user)
    }

    if (!isEqual(currentOrganization, data.current_organization)) {
      setCurrentOrganization(data.current_organization)
    }
  }, [
    error,
    isLoading,
    data,
    metadata,
    currentOrganization,
    setBuildCommit,
    setCurrentOrganization,
    setLastSeenLoggedInAtToNow,
    setUser,
    user,
  ])

  if (error) {
    return <PageError error="Network error fetching data." />
  }

  if (isLoading) {
    // TODO(mime): technically this needs a OrganizationThemeProvider around it.
    return (
      <LoaderWrapper>
        <Loader large />
      </LoaderWrapper>
    )
  }

  if (!data) {
    return <PageError error="Data load error." />
  }

  const initialQueryParams = getQueryParams(location.search)
  const wrappedProps = {data, initialQueryParams, ...props}

  let analyticsPageName = metadata?.url_name
  if (typeof screenOptions.analyticsPageNameOverride === 'string') {
    analyticsPageName = screenOptions.analyticsPageNameOverride
  } else if (typeof screenOptions.analyticsPageNameOverride === 'function') {
    analyticsPageName = screenOptions.analyticsPageNameOverride(wrappedProps)
  }

  return (
    <Page
      currentOrganization={data.current_organization}
      analyticsPageName={analyticsPageName}
      screenOptions={screenOptions}
      wrappedProps={wrappedProps}
    >
      <Wrapped
        data={{...data}}
        initialQueryParams={initialQueryParams}
        isLoading={isLoading}
        {...omit(props, internalPropsKeys)}
      />
    </Page>
  )
}

export default DataProviderWithRestAPI
